import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { validatePassword, validateCodeLength } from "../libs/validateLib";
import "./ResetPassword.css";
import { useAppContext } from "../libs/contextLib";
import logo from '../Elevate_DataSync_Formerlylockup_RGB_Color.svg';
import axios from "axios";

export default function ResetPassword() {
  const history = useHistory();
  const { getEmail } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const [fields, handleFieldChange] = useFormFields({
    emailCode: "",
    email: (getEmail ?? params.get('email') ?? ''),
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [ getError, setError ] = useState(null);

  function validateCodeForm() {
    return fields.email.length > 0 && fields.email.includes('@');
  }

  function validateResetForm() {
    let validPassword = validatePassword(
      fields.password,
      fields.confirmPassword,
      document.getElementById("validatePasswordError")
    );

    let validCode = validateCodeLength(
      fields.emailCode,
      document.getElementById("validateOTPError")
    );

    return (
      fields.emailCode.length >= 6 &&
      fields.password.length >= 1 &&
      fields.confirmPassword.length >= 1 &&
      validPassword &&
      validCode
    );
  }

  async function startForgotPassword(event) {
    event.preventDefault();
    setIsSendingCode(true);
    const errormsg = 'Error occurred during resetting your password. ' +
        'Please try again later. If the problem persists, please contact your administrator';
    try {
      const user = await axios.post('/api/forgotpassword', {email: fields.email});
      if(user.data.error != null && user.data.error === 'true') {
        setError(errormsg);
      } else {
        setCodeSent(true);
        setError(null);
      }
    } catch (error) {
      setError(errormsg);
    }
    setIsSendingCode(false);
  }

  async function confirmPasswordForget(event) {
    event.preventDefault();

    setIsConfirming(true);
    const errormsg = 'Error occurred during resetting your password. ' +
      'Please make sure you enter a valid verification code. If the problem persists, please contact your administrator';

    try {
      let user = await axios.post('/api/forgotpasswordconfirm', { email: fields.email, emailCode: fields.emailCode, password: fields.password});
      if(user.data.error != null && user.data.error === 'true') {
        setError(errormsg);
        setIsConfirming(false);
      } else {
        setError(null);
        redirectLogin();
      }
    } catch (error) {
      setError(errormsg);
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={startForgotPassword}>
        <h1>
          <img className="App-logo" alt="logo" src={logo}></img>
        </h1>
        <hr />
        <p>Change password for a Data Sync account.</p>
        {getError ? (
          <div
            className="alert alert-danger"
            id="genericError"
            style={{ display: "block" }}
          >
            {getError}
          </div>
        ) : null}

        <div id="validateOTPError" />

        <FormGroup bsSize="large" controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            autoComplete="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isSendingCode}
          disabled={!validateCodeForm()}
        >
          Change Password
        </LoaderButton>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={confirmPasswordForget}>
        <h1>
          <img className="App-logo" alt="logo" src={logo}></img>
        </h1>
        <hr />
        <p>Let's reset your password.</p>
        <p>
          If the email address entered matches a valid Data Sync account, you'll
          receive an email with a validation code. Please enter the code and
          your new password.
        </p>
        {getError ? (
          <div
            className="alert alert-danger"
            id="genericError"
            style={{ display: "block" }}
          >
            {getError}
          </div>
        ) : null}

        <div id="validateOTPError" />

        <FormGroup bsSize="large" controlId="emailCode">
          <ControlLabel>Verification Code</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            autoComplete="one-time-code"
            value={fields.emailCode}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <hr />
        <div id="validatePasswordError" />
        <FormGroup bsSize="large" controlId="password">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup bsSize="large" controlId="confirmPassword">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isConfirming}
          disabled={!validateResetForm()}
        >
          Reset Password
        </LoaderButton>
      </form>
    );
  }

  function redirectLogin() {
    history.push("/login?reset");
  }


  return (
    <div className="ResetPassword">
      { !codeSent ? renderRequestCodeForm() : renderConfirmationForm() }
    </div>
  );
}