import React from "react";
import ReactDOM from "react-dom";
import { Glyphicon } from "react-bootstrap";

export function validatePassword(password, confirmPassword, p) {
  // Init empty error array
  let requirements = [];

  // check for special characters
  let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  requirements.push({
    text: "A special character",
    met: format.test(password),
  });

  // check for a numeric
  format = /\d/;
  requirements.push({
    text: "A numeric character",
    met: format.test(password),
  });

  // check for a capital letter
  format = /[A-Z]/;
  requirements.push({
    text: "A capital (uppercase) letter",
    met: format.test(password),
  });

    // check for a lower case letter
    format = /[a-z]/;
    requirements.push({
      text: "A lowercase letter",
      met: format.test(password),
    });
  
  // check password for 8 characters or more
  requirements.push({
    text: "Minimum 8 characters",
    met: password && password.length >= 8,
  });

  // check if passwords match
  requirements.push({
    text: "Matching confirmation password",
    met: password === confirmPassword,
  });

  const allMet = requirements.every((r) => r.met);

  // show or hide the error message
  if (p !== null && password?.length > 0) {
    if (allMet) {
      p.style = "display: none";
    } else {
      p.style = "display: block";
    }

    ReactDOM.render(
      <ul className="list-group">
        {requirements.map((r) => (
          <li
            className={`list-group-item list-group-item-${
              r.met ? "success" : "danger"
            }`}
          >
            <Glyphicon glyph={r.met ? "ok" : "remove"} /> {r.text}
          </li>
        ))}
      </ul>,
      p
    );
  }

  return allMet;
}

export function validateCodeLength(code, p) {
  let error_msg = null;

  if (code !== "") {
    if (code.length < 6) error_msg = "Verification code requires 6 characters";
    if (/\W+/.test(code))
      error_msg = "Verification code requires alphanumeric characters";

    if (p !== null) {
      // show or hide the error message
      if (error_msg !== null) {
        p.className = "alert alert-danger";
        p.textContent = error_msg;
        p.style = "display: block";
      } else {
        p.style = "display: none";
      }
    }
  }

  return !error_msg;
}
