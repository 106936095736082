import React, {useState} from "react";
import {ControlLabel, FormControl, FormGroup, Glyphicon} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import {useAppContext} from "../libs/contextLib";
import {useFormFields} from "../libs/hooksLib";
import "./Login.css";
import {Link, useHistory} from "react-router-dom";
import logo from '../Elevate_DataSync_Formerlylockup_RGB_Color.svg';
import axios from "axios";

export default function Login() {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [ confirmed ] = useState(params.get("confirmed") != null);
  const [ error ] = useState(params.get("error") != null);
  const [ session, ] = useState(params.get("session") ?? '');
  const [ reset ] = useState(params.get("reset")!= null);
  const { setEmail } = useAppContext();
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [ fields, handleFieldChange ] = useFormFields({
    email: "",
    password: ""
  });
  const [ getError, setError ] = useState(null);

  function validateEmail() {
    return fields.email.length > 0 && fields.email.includes('@');
  }

  function validatePassword() {
    return fields.password.length >= 1;
  }

  async function handleSignin(event) {
    event.preventDefault();
    setIsLoggingIn(true);
    const errormsg = 'Error occurred during login. Please try again later. If the problem persists, please contact your administrator';
    try {
      const sessionParam = session ? { sessionMode : session } : {}
      const loginResult = await axios.post('/api/login', {...sessionParam, ...{ email: fields.email, password: fields.password }});
      if(loginResult.data.error != null && loginResult.data.error === 'true') {
        setError(loginResult.data.message);
      } else {
        window.location.href=loginResult.data.redirecturl;
      }
    } catch (error) {
      setError(errormsg);
    }
    setIsLoggingIn(false);
  }

  function redirectForgotPassword() {
    setEmail(fields.email);
    history.push("/reset");
  }

  function renderLoginForm() {
    return (
      <form onSubmit={handleSignin}>
        <h1>
          <img className="App-logo" alt="logo" src={logo}></img>
        </h1>
        <hr />
          <div>
          {  error &&
            <p className="alert alert-danger">
              An error occurred during login. Please try again later.
            </p>
          }
          {  confirmed &&
            <p className="alert alert-success">
              <Glyphicon glyph="ok" /> Your Data Sync account has successfully been confirmed.
            </p>
          }
          {  reset &&
            <p className="alert alert-success">
              <Glyphicon glyph="ok" /> Your Data Sync password has successfully been reset.
            </p>
          }
          <p>Sign in to your Data Sync account.</p>
          </div>
        {getError ? (
          <div
            className="alert alert-danger"
            id="genericError"
            style={{ display: "block" }}
          >
            {getError}
          </div>
        ) : null}


        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            autoComplete="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            autoComplete="current-password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoggingIn}
          disabled={!validatePassword() || !validateEmail()}
        >
          Submit
        </LoaderButton>
        <hr/>
        <div>
          <Link onClick={redirectForgotPassword}>
            Forgot password?
          </Link>
          <a href='https://www.instructure.com/policies/acceptable-use' style={{ "margin-bottom": "0px" }}>
            Acceptable Use Policy
          </a>
          <a href='https://www.instructure.com/canvas/privacy' style={{ "margin-bottom": "0px" }}>
            Privacy Policy
          </a>
        </div>
      </form>
    );
  }

  return (
    <div className="Login">
      { renderLoginForm() }
    </div>
  );

}