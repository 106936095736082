import React, { useState } from "react";
import "./App.css";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";

function App() {
  const [getEmail, setEmail] = useState(null);

  return (
      <div className="App container">
        <AppContext.Provider value={{ getEmail, setEmail }}>
          <Routes />
        </AppContext.Provider>
      </div>
    );
  }

export default App;