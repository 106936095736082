import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import {HelpBlock, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onErrorString } from "../libs/errorLib";
import {validateCodeLength, validatePassword} from "../libs/validateLib";
import "./ConfirmAccount.css";
import logo from '../Elevate_DataSync_Formerlylockup_RGB_Color.svg';

import axios from "axios";

export default function ConfirmAccount() {
  const history = useHistory();
  const [setPasswordStep, setSetPasswordStep] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirming, setIsConfirmingVerificationCode] = useState(false);
  const [getError, setError ] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const [token, ] = useState(params.get('token') ?? '');
  const [emailURLParam, ] = useState(params.get('email') ?? '');
  const [email, setEmail] = useState('');
    const [fields, handleFieldChange] = useFormFields({
    code: "",
    emailCode: "",
    password: "",
    confirmPassword: "",
  });

  function isSubmitVisible() {
    let validPassword = validatePassword(fields.password,fields.confirmPassword,document.getElementById("validatePasswordError"));
    return (
      fields.password.length >= 1 &&
      fields.confirmPassword.length >= 1 &&
      validPassword
    );
  }

  function isVerifyVisible() {
    return validateCodeLength(
        fields.emailCode,
        document.getElementById("validateOTPError")
    );
  }

  async function validateEmailAvailable() {
    return params.get('email') !== null;    
  }

  async function handleConfirm(event) {
    event.preventDefault();
    setSubmitting(true);
    const errormsg = 'Unable to set password. Please contact your administrator.'
    try {
      let user = await axios.post('/api/setpassword', { email: email, password: fields.password, token });
      if(user.data.error != null && user.data.error === 'true') {
        setError(errormsg);
      } else {
        setSetPasswordStep(false);
        setError(null);
      }
    } catch (error) {
      setError(errormsg);
    }
    setSubmitting(false);
  }


  async function handleConfirmEmailOTP(event) {
    event.preventDefault();
    setIsConfirmingVerificationCode(true);
    const errormsg = 'Unable to confirm user account. Please contact your administrator.'
    try {
      let user = await axios.post('/api/confirmemail', { emailCode: fields.emailCode, email: email, password: fields.password});
      if(user.data.error != null && user.data.error === 'true') {
          setError(errormsg);
      } else {
          setError(null);
          redirectToLoginConfirm();
      }
    } catch (error) {
      setError(errormsg);
    }
    setIsConfirmingVerificationCode(false);
  }

  function redirectToLoginConfirm() {
    history.push("/login?confirmed");
  }

  function renderError() {
    let errormsg;
    if (emailURLParam) {
      errormsg = <div>The invitation is no longer valid. Please contact <a href='mailto:support@kimonocloud.com'>Instructure support</a> for further assistance.</div>;
    } else {
      errormsg = getError;
    }
    return (
      <div>
      { errormsg &&
        <div
            className="alert alert-danger"
            id="genericError"
            style={{ display: "block" }}
        >
          {errormsg}
        </div>}
      </div>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirm}>
        <h1>
          <img className="App-logo" alt="logo" src={logo}></img>
        </h1>
        <hr />
        {renderError()}

        <div id="validateOTPError" />
        <HelpBlock>
          A Data Sync account was recently established. Please confirm your account.
        </HelpBlock>
        <FormGroup bsSize="large" controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus type="email"
            autoComplete="email"
            value={email}
            disabled={validateEmailAvailable()}
          />
        </FormGroup>
        <hr />
        <div id="validatePasswordError" />
        <FormGroup bsSize="large" controlId="password">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            disabled={!email}
            type="password"
            autoComplete="new-password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup bsSize="large" controlId="confirmPassword">
          <ControlLabel>Confirm New Password</ControlLabel>
          <FormControl
            disabled={!email}
            type="password"
            autoComplete="new-password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isSubmitting}
          disabled={!isSubmitVisible()}
        >
          Submit
        </LoaderButton>
      </form>
    );
  }

  function renderOTPForm() {
    return (
        <form onSubmit={handleConfirmEmailOTP}>
          <h1>
            <img className="App-logo" alt="logo" src={logo}></img>
          </h1>
          <hr />
          {renderError()}

          <div id="validateOTPError" />
          <HelpBlock>
            Please confirm your account recovery email, enter the verification
            code.
          </HelpBlock>

          <FormGroup bsSize="large" controlId="emailCode">
            <ControlLabel>Verification Code</ControlLabel>
            <FormControl
                autoFocus
                type="text"
                autoComplete="one-time-code"
                value={fields.emailCode}
                onChange={handleFieldChange}
            />
          </FormGroup>
          <LoaderButton
              block
              type="submit"
              bsSize="large"
              isLoading={isConfirming}
              disabled={!isVerifyVisible()}
          >
            Verify
          </LoaderButton>
        </form>
    );
  }

  useEffect(() => {
      const tryConfirmEmail = async () => {
          try {
              if (!token) {
                setError("Unable to confirm user account: missing token.");
                return;
              }
              window.history.replaceState(window.history.state, "",
              window.location.href.replace(/\?token=.*/, ''));
              const result = await axios.post('/api/startconfirmemail', {token: token});
              if (result.data.error) {
                  setError("Unable to confirm user account: invalid token. Please contact your administrator.");
              } else {
                  setEmail(result.data['email']);
              }
          } catch (error) {
              setError(onErrorString(error));
          }
      }
      tryConfirmEmail()
          .catch(console.error);
  },[]);

  return (
    <div className="ConfirmAccount">
      {setPasswordStep ? renderConfirmationForm() : renderOTPForm()}
    </div>
  );
}